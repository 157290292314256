import React from 'react'

const ProjectDetails = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div>
                <div className="shape1 float-bob-x"><img src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png" alt="banner shape" /></div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Project Details</h2>
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            {/* Breadcrumb NavXT 7.2.0 */}
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a property="item" typeof="WebPage" title="Go to Ontech." href="https://bracketweb.com/ontechwp" className="home">
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">Project Details</span>
                                    <meta property="url" content="https://bracketweb.com/ontechwp/project-details/" />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="31" class="elementor elementor-31">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-2cb0989 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2cb0989" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9896b1a" data-id="9896b1a" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-068dbdd elementor-widget elementor-widget-ontech-image-box" data-id="068dbdd" data-element_type="widget" data-widget_type="ontech-image-box.default">
                                    <div class="elementor-widget-container">
                                        <div class="projects-details-img1">
                                            <div class="inner">
                                                <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-details-img1.jpg" alt="alt" title="projects-details-img1" /> </div>

                                            <div class="content-box">
                                                <ul class="ml-0">
                                                    <li>
                                                        <div class="icon-box">
                                                            <span aria-hidden="true" class="  icon-users2"></span> </div>

                                                        <div class="text-box">
                                                            <p>Clients:</p>
                                                            <h4>Nor 41 Road, USA</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="icon-box">
                                                            <span aria-hidden="true" class="   icon-copy"></span> </div>

                                                        <div class="text-box">
                                                            <p>Category:</p>
                                                            <h4>UI Design</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="icon-box">
                                                            <span aria-hidden="true" class="   icon-clock2"></span> </div>

                                                        <div class="text-box">
                                                            <p>Date:</p>
                                                            <h4>March 05, 2023</h4>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-9fd15b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9fd15b7" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-afe89b0" data-id="afe89b0" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-17140fc projects-details-text1 elementor-widget elementor-widget-html" data-id="17140fc" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <div class="title-box">
                                            <h2>Visual UI designer</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-0ad5463 elementor-widget elementor-widget-heading" data-id="0ad5463" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">

                                        <h3 class="elementor-heading-title elementor-size-default">Project Overview</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-1cd5ccb elementor-widget elementor-widget-heading" data-id="1cd5ccb" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. Duis aute irure dolor</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-bda7531 elementor-widget elementor-widget-heading" data-id="bda7531" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">There are many variations of passages of lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour or randomised words which don't look even slightly believable. Egestas dictum
                                            lectus diam commodo. Marketing tristique nunc faucibus sit tortor commodo aliquet commodo quam. Id suspendisse vel in non arcu, interdum quis placerat accumsan varius </p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-99631a8 elementor-widget elementor-widget-heading" data-id="99631a8" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">What Is A UI Designer?</h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-30ba977 elementor-widget elementor-widget-heading" data-id="30ba977" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Brand identity is the company's soul embodied in unique graphics. It is consistent in print, communications, and digital form. Diam enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                                            ea commodo consequat. Duis aute irure dolor</p>
                                    </div>
                                </div>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-d4b8586 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d4b8586" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1cc8d8a" data-id="1cc8d8a" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-8f9880b projects-details-text3-img elementor-widget elementor-widget-image" data-id="8f9880b" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-details-img2.jpg" title="projects-details-img2" alt="alt" loading="lazy" /> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-465effb projects-details-text3-content" data-id="465effb" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-dbfdf23 elementor-widget elementor-widget-heading" data-id="dbfdf23" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Our goal</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-c65d7a9 elementor-widget elementor-widget-heading" data-id="c65d7a9" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <p class="elementor-heading-title elementor-size-default">Learn the latest in UI design like how to build seamless interfaces, play with bold gradients, and how to use geometric layers to differentiate your product.dolores eos qui ratione voluptatem sequi nesciunt
                                                            sed quia non numquam eius modi tempora incidunt ut labore eos qui ratione</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-68ae42d elementor-widget elementor-widget-html" data-id="68ae42d" data-element_type="widget" data-widget_type="html.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="text-box2">
                                                            <h3>The Idea Of Corporate Graphics</h3>
                                                            <ul class="ml-0">
                                                                <li>
                                                                    <div class="icon-box">
                                                                        <span class="icon-check-circle"></span>
                                                                    </div>
                                                                    <div class="text-box">
                                                                        <p>Master Modern UI
                                                                        </p>
                                                                    </div>
                                                                </li>

                                                                <li>
                                                                    <div class="icon-box clr2">
                                                                        <span class="icon-check-circle"></span>
                                                                    </div>
                                                                    <div class="text-box">
                                                                        <p>Corporate Graphics
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-d143eba elementor-widget elementor-widget-html" data-id="d143eba" data-element_type="widget" data-widget_type="html.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="text-box3">
                                                            <h2>User Interface (UI) Designer</h2>
                                                            <p>Learn the latest in UI design like how to build seamless interfaces, play with bold gradients, and how to use geometric layers to differentiate your product.dolores eos qui ratione voluptatem sequi
                                                                nesciunt sed quia non numquam eius modi tempora incidunt ut labore eos qui ratione </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-83dc750 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="83dc750" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-56a0315" data-id="56a0315" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-0658419 elementor-widget elementor-widget-ontech-call-to-action" data-id="0658419" data-element_type="widget" data-widget_type="ontech-call-to-action.default">
                                    <div class="elementor-widget-container">
                                        <div class="projects-details-text4">
                                            <div class="projects-details-text4-bg" style={{backgroundImage: "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-details-text4-bg.jpg)"}}></div>

                                            <div class="text-box">
                                                <h2 class="cta-two__title">Get A Free Visual Design Consultation</h2>
                                            </div>

                                            <div class="btn-box">
                                                <a class="thm-btn" href="https://bracketweb.com/ontechwp/it-consultancy/">Lets Talk</a> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-36c123a4 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="36c123a4" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2b2bf90b" data-id="2b2bf90b" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-f3226d9 elementor-widget elementor-widget-ontech-google-maps" data-id="f3226d9" data-element_type="widget" data-widget_type="ontech-google-maps.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-custom-embed">
                                            <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ProjectDetails
