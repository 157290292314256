import React from 'react'

function mechanicalroad() {
  return (
    <div>
       <h1>Hello world</h1>
    </div>
  )
}

export default mechanicalroad
