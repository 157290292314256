import React from 'react'

const Signin = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div
                    className="page-header__gradient"
                    style={{
                        backgroundImage:
                            'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)',
                    }}
                ></div>
                <div className="shape1 float-bob-x">
                    <img
                        src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png"
                        alt="banner shape"
                    />
                </div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Sign In</h2>

                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a
                                        property="item"
                                        typeof="WebPage"
                                        title="Go to Ontech."
                                        href="https://bracketweb.com/ontechwp"
                                        className="home"
                                    >
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">
                                        Sign In
                                    </span>
                                    <meta
                                        property="url"
                                        content="https://bracketweb.com/ontechwp/sign-in/"
                                    />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="708" class="elementor elementor-708">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-c210be3 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c210be3" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8aa1ad9" data-id="8aa1ad9" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-c027343 elementor-widget elementor-widget-ontech-login" data-id="c027343" data-element_type="widget" data-widget_type="ontech-login.default">
                                    <div class="elementor-widget-container">


                                        <section class="signup-page sign-in">
                                            <div class="container">
                                                <div class="row">

                                                    <div class="col-xl-6">
                                                        <div class="signup-page__content">
                                                            <div class="title-box">
                                                                <h2>Wlcome Back To Agency</h2>
                                                            </div>
                                                            <div class="signup-page__form">
                                                                <div class="title">
                                                                    <h2>Sign in</h2>
                                                                </div>
                                                                <form id="ontech-login" class="default-form2" method="post">
                                                                    <div class="login-result text-center"></div>
                                                                    <input type="hidden" id="security" name="security" value="14eb97a8a4" /><input type="hidden" name="_wp_http_referer" value="/ontechwp/sign-in/" />
                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="input-box">
                                                                                <label>User Name</label>
                                                                                <input type="text" name="username" value="" placeholder="Username or Email Address* " required="" />
                                                                                <div class="icon-holder">
                                                                                    <span class="icon-cancel"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="input-box">
                                                                                <label>Password </label>
                                                                                <input type="text" name="password" placeholder="Password*  " />
                                                                                <div class="icon-holder">
                                                                                    <span class="icon-visibility"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="button-box">
                                                                                <button class="thm-btn" type="submit" data-loading-text="Please wait...">
                                                                                    Login												</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="signup-page__form-checkbox-outer">
                                                                                <div class="signup-page__form-checkbox">
                                                                                    <input type="checkbox" name="remember" id="tag" />
                                                                                    <label for="tag"><span></span>Remember Me?</label>
                                                                                </div>

                                                                                <div class="forgot-password">
                                                                                    <a href="https://bracketweb.com/ontechwp/my-account/lost-password/">Forgot your Password?</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </form>
                                                            </div>
                                                            <p>
                                                                Do not have an account yet? <a class="" href="https://bracketweb.com/ontechwp/sign-up/">Sign up</a> </p>
                                                        </div>
                                                    </div>



                                                    <div class="col-xl-6">
                                                        <div class="signup-page__img">
                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/signin-page-img1.jpg" alt="alt" title="signin-page-img1" /> </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Signin
