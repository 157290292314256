import React from 'react'

const Projects = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div>
                <div className="shape1 float-bob-x"><img src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png" alt="banner shape" /></div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>OUR PROJECTS</h2>
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            {/* Breadcrumb NavXT 7.2.0 */}
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a property="item" typeof="WebPage" title="Go to Ontech." href="https://bracketweb.com/ontechwp" className="home">
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">Projects</span>
                                    <meta property="url" content="https://bracketweb.com/ontechwp/projects/" />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="27" class="elementor elementor-27">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-9213246 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9213246" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ce81efe" data-id="ce81efe" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-0fcee07 elementor-widget elementor-widget-ontech-portfolio" data-id="0fcee07" data-element_type="widget" data-widget_type="ontech-portfolio.default">
                                    <div class="elementor-widget-container">

                                        <section class="projects-one projects-one--projects">
                                            <div class="container">

                                                <div class="row">

                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img1.jpg" alt="alt" title="projects-v1-img1" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1">Tech</p>
                                                                            <h3>
                                                                                <a class="" href="https://bracketweb.com/ontechwp/project-details/">Tech cover industry</a> </h3>
                                                                            <p class="text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img2.jpg" alt="alt" title="projects-v1-img2" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1 text1">Tech</p>
                                                                            <h3>
                                                                                <a class=" " href="https://bracketweb.com/ontechwp/project-details/">Application Integration</a> </h3>
                                                                            <p class="text2 text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img3.jpg" alt="alt" title="projects-v1-img3" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1 text1 text1">Tech</p>
                                                                            <h3>
                                                                                <a class="  " href="https://bracketweb.com/ontechwp/project-details/">Database Design</a> </h3>
                                                                            <p class="text2 text2 text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img4.jpg" alt="alt" title="projects-v1-img4" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1 text1 text1 text1">Cyber</p>
                                                                            <h3>
                                                                                <a class="   " href="https://bracketweb.com/ontechwp/project-details/">Cyber Program Management</a> </h3>
                                                                            <p class="text2 text2 text2 text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img5.jpg" alt="alt" title="projects-v1-img5" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1 text1 text1 text1 text1">Marketing</p>
                                                                            <h3>
                                                                                <a class="    " href="https://bracketweb.com/ontechwp/project-details/">Digital Marketing</a> </h3>
                                                                            <p class="text2 text2 text2 text2 text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div class="projects-one__single">
                                                            <div class="projects-one__single-img">
                                                                <div class="inner">
                                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-img6.jpg" alt="projects-v1-img6" title="projects-v1-img6" />
                                                                    <div class="overlay-content">
                                                                        <div class="shape1">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape1.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape2">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape2.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape3">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape3.png" alt="alt" />
                                                                        </div>
                                                                        <div class="shape4">
                                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/projects-v1-shape4.png" alt="alt" />
                                                                        </div>
                                                                        <div class="content-box">
                                                                            <p class="text1 text1 text1 text1 text1 text1">Tech</p>
                                                                            <h3>
                                                                                <a class="     " href="https://bracketweb.com/ontechwp/project-details/">Business Consulting Tech</a> </h3>
                                                                            <p class="text2 text2 text2 text2 text2 text2">There are many variations of passages</p>
                                                                        </div>

                                                                        <div class="icon-box">
                                                                            <a href="https://bracketweb.com/ontechwp/project-details/">
                                                                                <span aria-hidden="true" class="  icon-right-arrow3"></span>											</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Projects
