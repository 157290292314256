import React, { useState, useEffect } from 'react';


const MobileNav = () => {
    const [isNavVisible, setNavVisible] = useState(false); // Initially hide the navigation in desktop view
    const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            // Check if the window width is less than 768px (typical mobile view)
            if (window.innerWidth < 768) {
                setNavVisible(true); // Show the navigation in mobile view
            } else {
                setNavVisible(false); // Hide the navigation in desktop view
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial check for window width on component mount
        handleResize();

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ backgroundColor: "black" }}>
            {isNavVisible && ( // Render the navigation only if it's visible
                <div className={`mobile-nav-wrapper ${isNavVisible ? 'visible' : ''}`} style={{ textAlign: 'center', padding: '10px', background: isNavVisible ? 'black' : 'transparent', transition: 'background 0.3s', width: "380px" }}>
                    <div style={{ backgroundColor: "black" }}>
                        {/* <img src='assets1/images/newlogo.PNG' style={{ maxWidth: '100%' }} /> */}
                        <h5><b style={{color:"#ffc107",fontSize:"20px"}}><b style={{color:"#ffc107"}}>SHAWTECH TRAINING SERVICES</b></b></h5>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileNav;
