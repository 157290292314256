import React from 'react'

const Faq = () => {
    return (
        <>


            <section className="page-header">
                <div className="page-header__bg"></div>
                <div
                    className="page-header__gradient"
                    style={{
                        backgroundImage:
                            'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)',
                    }}
                ></div>
                <div className="shape1 float-bob-x">
                    <img
                        src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png"
                        alt="banner shape"
                    />
                </div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Faq</h2>

                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a
                                        property="item"
                                        typeof="WebPage"
                                        title="Go to Ontech."
                                        href="https://bracketweb.com/ontechwp"
                                        className="home"
                                    >
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">
                                        Faq
                                    </span>
                                    <meta
                                        property="url"
                                        content="https://bracketweb.com/ontechwp/faq/"
                                    />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="476" class="elementor elementor-476">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-4ef68ec elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="4ef68ec" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d971c3e" data-id="d971c3e" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-83e109f elementor-widget elementor-widget-ontech-faq" data-id="83e109f" data-element_type="widget" data-widget_type="ontech-faq.default">
                                    <div class="elementor-widget-container">

                                        <section class="faq-page">
                                            <div class="faq-page__pattern" style={{ backgroundImage: "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/faq-page-pattern.jpg)" }}></div>
                                            <div class="container">
                                                <div class="title-box text-center">
                                                    <p class="">How To Help?</p>
                                                    <h2 class="">Frequently Asked Questions</h2>
                                                </div>
                                                <div class="row">

                                                    <div class="col-xl-7">
                                                        <div class="faq-page__accrodion">

                                                            <div class="faq-page__accrodion-box">
                                                                <div class="accrodion-grp" data-grp-name="faq-one-accrodion">
                                                                    <div class="accrodion active">
                                                                        <div class="accrodion-title">
                                                                            <h4>Why is there a need to increase digital access?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="accrodion ">
                                                                        <div class="accrodion-title">
                                                                            <h4>What user-level security measures are in place?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="accrodion ">
                                                                        <div class="accrodion-title">
                                                                            <h4>Can I manage multiple entities from one database?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="accrodion ">
                                                                        <div class="accrodion-title">
                                                                            <h4>Does your payroll software offer the ability to do job costing?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="accrodion ">
                                                                        <div class="accrodion-title">
                                                                            <h4>How do we submit hours to you?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="accrodion ">
                                                                        <div class="accrodion-title">
                                                                            <h4>Why is there a need to increase digital access?</h4>
                                                                        </div>
                                                                        <div class="accrodion-content">
                                                                            <div class="inner">
                                                                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati
                                                                                    cupiditate non provident et harum quidem rerum facilis est et expedita distinctio nam libero tempore, </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-5 wow slideInRight" data-wow-delay="500ms" data-wow-duration="2500ms">
                                                        <div class="faq-page__img">
                                                            <div class="inner">
                                                                <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/faq-page-img1.jpg" alt="faq-page-img1" title="faq-page-img1" /> </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-46509afd elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="46509afd" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7b2cc35c" data-id="7b2cc35c" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-2edb9d3e elementor-widget elementor-widget-ontech-google-maps" data-id="2edb9d3e" data-element_type="widget" data-widget_type="ontech-google-maps.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-custom-embed">
                                            <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Faq
