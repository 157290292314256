import React from 'react'

const TeamDetails = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div
                    className="page-header__gradient"
                    style={{
                        backgroundImage:
                            'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)',
                    }}
                ></div>
                <div className="shape1 float-bob-x">
                    <img
                        src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png"
                        alt="banner shape"
                    />
                </div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Team Details</h2>

                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span
                                    property="itemListElement"
                                    typeof="ListItem"
                                >
                                    <a
                                        property="item"
                                        typeof="WebPage"
                                        title="Go to Ontech."
                                        href="https://bracketweb.com/ontechwp"
                                        className="home"
                                    >
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span
                                    property="itemListElement"
                                    typeof="ListItem"
                                >
                                    <span property="name" className="post post-page current-item">
                                        Team Details
                                    </span>
                                    <meta
                                        property="url"
                                        content="https://bracketweb.com/ontechwp/team-details/"
                                    />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>



            <div data-elementor-type="wp-page" data-elementor-id="35" class="elementor elementor-35">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-5d14a2a elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="5d14a2a" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9c278c2" data-id="9c278c2" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-c50e666 elementor-widget elementor-widget-ontech-team" data-id="c50e666" data-element_type="widget" data-widget_type="ontech-team.default">
                                    <div class="elementor-widget-container">

                                
                                        <section class="team-details">
                                            <div class="container">
                                                <div class="row">
                                                  
                                                    <div class="col-xl-5">
                                                        <div class="team-details__img">
                                                            <div class="inner">
                                                                <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/team-details-img1.jpg" alt="alt" title="team-details-img1"/> </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-xl-7">
                                                        <div class="team-details__content">
                                                            <div class="title-box">
                                                                <h2>Adam Andeson</h2>
                                                                <p>Co Founder &amp; CEO</p>
                                                            </div>

                                                            <div class="text-box">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra accu</p>
                                                            </div>

                                                            <div class="team-details__content-text1">
                                                                <div class="row">
                                                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                                                        <div class="team-details__content-text1-single">
                                                                            <div class="icon-box">
                                                                                <span aria-hidden="true" class="  icon-email2"></span> </div>

                                                                            <div class="content-box">
                                                                                <h3>Email Me</h3>
                                                                                <p>
                                                                                    <a href="mailto:help12@gmail.com">Help12@gmail.com</a> <br/>
                                                                                        <a href="mailto:suport1@gmail.com">Suport1@gmail.com</a> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                                                        <div class="team-details__content-text1-single">
                                                                            <div class="icon-box">
                                                                                <span aria-hidden="true" class="   icon-location-filled"></span> </div>

                                                                            <div class="content-box">
                                                                                <h3>Address</h3>
                                                                                <p>
                                                                                    21 King Street 5th Floor <br/> Hamilton, Ontario Canada </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="team-details__content-social-links">
                                                                <div class="title">
                                                                    <p>Social </p>
                                                                </div>

                                                                <ul class="ml-0">
                                                                    <li>
                                                                        <a href="https://www.twitter.com/">
                                                                            <span aria-hidden="true" class="  fab fa-twitter"></span>										</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.facebook.com/">
                                                                            <span aria-hidden="true" class="  fab fa-facebook"></span>										</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.pinterest.com/">
                                                                            <span aria-hidden="true" class="  fab fa-pinterest-p"></span>										</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.instagram.com/">
                                                                            <span aria-hidden="true" class="  fab fa-instagram"></span>										</a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div class="team-details__content-bottom">
                                                                <div class="left-text">
                                                                    <h3>Do You Have An Emergency?</h3>
                                                                </div>

                                                                <div class="right-text">
                                                                    <h4> <span class="icon-phone-auricular"></span> <a href="tel:00881745651">+00(88) 17456 51 </a></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </section>
                                  

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-9d4aa77 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9d4aa77" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3be1bd3" data-id="3be1bd3" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-6f024ce elementor-widget elementor-widget-ontech-contact-form" data-id="6f024ce" data-element_type="widget" data-widget_type="ontech-contact-form.default">
                                    <div class="elementor-widget-container">

                                      
                                        <section class="contact-page contact-page--team-details">
                                            <div class="container">
                                                <div class="title text-center">
                                                    <h2></h2>
                                                </div>
                                                <div class="contact-page__bottom">
                                                    <div class="contact-page__bottom-pattern" style={{backgroundImage: "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/contact-page-pattern.jpg)"}}> </div>
                                                    <div class="contact-page__bottom-inner">
                                                        <div class="wpcf7 no-js" id="wpcf7-f469-p35-o1" lang="en-US" dir="ltr">
                                                            <div class="screen-reader-response">
                                                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                                <ul></ul>
                                                            </div>
                                                            <form action="/ontechwp/team-details/#wpcf7-f469-p35-o1" method="post" class="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                                                                <div style={{display: "none"}}>
                                                                    <input type="hidden" name="_wpcf7" value="469" />
                                                                    <input type="hidden" name="_wpcf7_version" value="5.8" />
                                                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f469-p35-o1" />
                                                                    <input type="hidden" name="_wpcf7_container_post" value="35" />
                                                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                                                </div>
                                                                <div class="contact-page__form contact-form-validated">
                                                                    <div class="row">
                                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                                            <div class="contact-page__input-box">
                                                                                <p><span class="wpcf7-form-control-wrap" data-name="name"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="User Name" value="" type="text" name="name" /></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                                            <div class="contact-page__input-box">
                                                                                <p><span class="wpcf7-form-control-wrap" data-name="email"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Email Address" value="" type="text" name="email" /></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                                            <div class="contact-page__input-box">
                                                                                <p><span class="wpcf7-form-control-wrap" data-name="phone"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Phone" value="" type="text" name="phone" /></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                                            <div class="contact-page__input-box">
                                                                                <p><span class="wpcf7-form-control-wrap" data-name="Subject"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Subject" value="" type="text" name="Subject" /></span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="contact-page__input-box">
                                                                                <p><span class="wpcf7-form-control-wrap" data-name="textarea-40"><textarea cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Your Message here" name="textarea-40"></textarea></span>
                                                                                </p>
                                                                            </div>
                                                                            <div class="contact-page__btn">
                                                                                <p><button type="submit" class="thm-btn">
                                                                                    Send A Message
                                                                                </button>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                     

                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-1d17d841 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="1d17d841" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-403a8b39" data-id="403a8b39" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-781b686d elementor-widget elementor-widget-ontech-google-maps" data-id="781b686d" data-element_type="widget" data-widget_type="ontech-google-maps.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-custom-embed">
                                            <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default TeamDetails
