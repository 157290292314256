import React from 'react'

const Signup = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div
                    className="page-header__gradient"
                    style={{
                        backgroundImage:
                            'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)',
                    }}
                ></div>
                <div className="shape1 float-bob-x">
                    <img
                        src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png"
                        alt="banner shape"
                    />
                </div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Sign Up</h2>

                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a
                                        property="item"
                                        typeof="WebPage"
                                        title="Go to Ontech."
                                        href="https://bracketweb.com/ontechwp"
                                        className="home"
                                    >
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">
                                        Sign Up
                                    </span>
                                    <meta
                                        property="url"
                                        content="https://bracketweb.com/ontechwp/sign-up/"
                                    />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="702" class="elementor elementor-702">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-319b352 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="319b352" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d30aa4d" data-id="d30aa4d" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-98da3bb elementor-widget elementor-widget-ontech-register" data-id="98da3bb" data-element_type="widget" data-widget_type="ontech-register.default">
                                    <div class="elementor-widget-container">


                                        <section class="signup-page">
                                            <div class="container">
                                                <div class="row">

                                                    <div class="col-xl-6">
                                                        <div class="signup-page__content">
                                                            <div class="title-box">
                                                                <h2>Join Our IT Agency</h2>
                                                            </div>
                                                            <div class="signup-page__form">
                                                                <div class="title">
                                                                    <h2>Sign up</h2>
                                                                </div>
                                                                <form id="ontech-registration" class="default-form2" method="post">
                                                                    <div class="registration-result text-center"></div>
                                                                    <input type="hidden" id="security" name="security" value="a0d2975594" /><input type="hidden" name="_wp_http_referer" value="/ontechwp/sign-up/" />
                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="input-box">
                                                                                <label>Email Address</label>
                                                                                <input type="email" name="singupEmail" value="" placeholder="Mahf*****@gmail.com" required="" />
                                                                                <div class="icon-holder">
                                                                                    <span class="icon-cancel"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="input-box">
                                                                                <label>Password </label>
                                                                                <input type="text" name="singupPassword" placeholder="* * * * * * * * *" />
                                                                                <div class="icon-holder">
                                                                                    <span class="icon-visibility"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div class="button-box">
                                                                                <button class="thm-btn" type="submit" data-loading-text="Please wait...">
                                                                                    Sign up												</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </form>
                                                            </div>
                                                            <p>
                                                                Do you have an account ? <a class="" href="https://bracketweb.com/ontechwp/sign-in/">Sign In</a> </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-6">
                                                        <div class="signup-page__img">
                                                            <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/signup-page-img1.jpg" alt="alt" title="signup-page-img1" /> </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </section>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Signup
