import React from 'react'

const ItCunsultancy = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div>
                <div className="shape1 float-bob-x"><img src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-shape1.png" alt="banner shape" /></div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>IT Consultancy</h2>
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            {/* Breadcrumb NavXT 7.2.0 */}
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a property="item" typeof="WebPage" title="Go to Ontech." href="https://bracketweb.com/ontechwp" className="home">
                                        <span property="name">Ontech</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">IT Consultancy</span>
                                    <meta property="url" content="https://bracketweb.com/ontechwp/it-consultancy/" />
                                    <meta property="position" content="2" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="29" class="elementor elementor-29">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-ca40c0e services-details elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ca40c0e" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b9e6cee" data-id="b9e6cee" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7aa6030 elementor-widget elementor-widget-ontech-sidebar" data-id="7aa6030" data-element_type="widget" data-widget_type="ontech-sidebar.default">
                                    <div class="elementor-widget-container">

                                        <div class="sidebar__single sidebar__services wow animated fadeInUp" data-wow-delay="0.1s">

                                            <div class="title-box">
                                                <div class="inner">
                                                    <div class="shape">
                                                        <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-title-shape.png" alt="alt" title="sidebar-title-shape" /> </div>
                                                    <h2 class="">Services List</h2>
                                                </div>
                                            </div>

                                            <div class="sidebar__services-box">
                                                <ul class="sidebar__services-box-list clearfix">
                                                    <li>
                                                        <a class="active" href="https://bracketweb.com/ontechwp/it-consultancy/">
                                                            IT Consultancy <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="normal" href="https://bracketweb.com/ontechwp/digital-marketing/">
                                                            Digital Marketing <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="normal" href="https://bracketweb.com/ontechwp/graphic-designing/">
                                                            Graphic Designing <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="normal" href="https://bracketweb.com/ontechwp/data-structuring/">
                                                            Data Structuring <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="normal" href="https://bracketweb.com/ontechwp/web-development/">
                                                            Web Development <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="normal" href="https://bracketweb.com/ontechwp/seo-and-marketing/">
                                                            SEO and Marketing  <span class="icon-right-arrow2"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-597b84f elementor-widget elementor-widget-ontech-sidebar" data-id="597b84f" data-element_type="widget" data-widget_type="ontech-sidebar.default">
                                    <div class="elementor-widget-container">

                                        <div class="sidebar__single sidebar__author wow animated fadeInUp" data-wow-delay="0.2s">
                                            <div class="sidebar__author-box text-center">
                                                <div class="shape1">
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-shape1.png" alt="alt" title="sidebar-shape1" /> </div>
                                                <div class="shape2">
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-shape2.png" alt="alt" title="sidebar-shape2" /> </div>
                                                <div class="shape3">
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-shape3.png" alt="sidebar-shape3" title="sidebar-shape3" /> </div>
                                                <div class="shape4">
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-shape4.png" alt="alt" title="sidebar-shape4" /> </div>
                                                <div class="sidebar__author-box-icon">
                                                    <span aria-hidden="true" class="  icon-bubble-message"></span> </div>
                                                <div class="sidebar__author-box-text">
                                                    <h3>Neque porro est qui dolorem ipsum quia quaed inventore veritatis et </h3>
                                                </div>
                                                <div class="sidebar__author-box-img">
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-img1.jpg" alt="alt" title="sidebar-img1" /> </div>
                                                <h2>Alex Handson</h2>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-4f59bb8 elementor-widget elementor-widget-ontech-sidebar" data-id="4f59bb8" data-element_type="widget" data-widget_type="ontech-sidebar.default">
                                    <div class="elementor-widget-container">

                                        <div class="sidebar__single sidebar__contact wow animated fadeInUp" data-wow-delay="0.3s">
                                            <div class="title-box">
                                                <div class="inner">
                                                    <div class="shape">
                                                        <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-title-shape.png" alt="alt" title="sidebar-title-shape" /> </div>
                                                    <h2 class="">Have Any Query</h2>
                                                </div>
                                            </div>

                                            <div class="sidebar__contact-box">
                                                <div class="sidebar__contact-box-img">
                                                    <div class="gradient-bg" style={{backgroundImage: "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-gradient-bg1.png)"}}></div>
                                                    <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sidebar-img2.jpg" alt="alt" title="sidebar-img2" />
                                                    <div class="overlay-content text-center">
                                                        <h3 class="">Have Questions? <br /> Nothing stops us from <br /> doing what we like</h3>
                                                        <div class="number-box">
                                                            <div class="icon-box">
                                                                <span aria-hidden="true" class="  icon-phone-auricular"></span> </div>

                                                            <div class="text-box">
                                                                <a href="tel:8801051351">+88 010 513 51 </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d09bdc9" data-id="d09bdc9" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-38e3ac5 elementor-widget elementor-widget-heading" data-id="38e3ac5" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">

                                        <h2 class="elementor-heading-title elementor-size-default">IT Consultancy</h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-bb83764 elementor-widget elementor-widget-heading" data-id="bb83764" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-9aa10d7 elementor-widget elementor-widget-heading" data-id="9aa10d7" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, inadipisci velit, sed quia non numquam eius modi tempora incidunt
                                            ipsum at vero eos et accusamus et iusto odio</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-837b501 elementor-widget elementor-widget-heading" data-id="837b501" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default">IT Conslting & Planning</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-56ac149 elementor-widget elementor-widget-heading" data-id="56ac149" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">Get the most out of your IT consulting services with sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation enim ad minima veniam, quis nostrum exercitationem
                                            ullam corporis
                                        </p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-59aa5f6 elementor-widget elementor-widget-html" data-id="59aa5f6" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <ul class="services-details__content-text1-list ml-0">
                                            <li>
                                                <div class="icon-box">
                                                    <span class="icon-check-circle"></span>
                                                </div>

                                                <div class="text-box">
                                                    <h4>Business IT Solutions</h4>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="icon-box">
                                                    <span class="icon-check-circle"></span>
                                                </div>

                                                <div class="text-box">
                                                    <h4>Identify Areas to Optimize</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-d2ee771 services-details__content-img1 elementor-widget elementor-widget-image" data-id="d2ee771" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img fetchpriority="high" decoding="async" width="740" height="455" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/services-details-img1.jpg" class="attachment-full size-full wp-image-565"
                                            alt="alt" srcset="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/services-details-img1.jpg 740w, https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/services-details-img1-570x350.jpg 570w, https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/services-details-img1-300x184.jpg 300w"
                                            sizes="(max-width: 740px) 100vw, 740px" /> </div>
                                </div>
                                <div class="elementor-element elementor-element-3d0edb4 elementor-widget elementor-widget-heading" data-id="3d0edb4" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. Labore et dolore
                                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-4bbe410 elementor-widget elementor-widget-ontech-info-box" data-id="4bbe410" data-element_type="widget" data-widget_type="ontech-info-box.default">
                                    <div class="elementor-widget-container">
                                        <ul class="services-details__content-text2-list ml-0">
                                            <li>
                                                <div class="icon-box">
                                                    <span aria-hidden="true" class="  icon-web-design"></span>
                                                    <div class="top-icon">
                                                        <span class="icon-check-circle"></span>
                                                    </div>
                                                </div>

                                                <div class="content-box">
                                                    <h3 class="">IT Disaster recovery maturity Assessment</h3>
                                                    <p class="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquamini</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon-box">
                                                    <span aria-hidden="true" class="   icon-data"></span>
                                                    <div class="top-icon">
                                                        <span class="icon-check-circle"></span>
                                                    </div>
                                                </div>

                                                <div class="content-box">
                                                    <h3 class=" ">Plan for the unexpected</h3>
                                                    <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquamini</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-1144d2c elementor-widget elementor-widget-ontech-image-box" data-id="1144d2c" data-element_type="widget" data-widget_type="ontech-image-box.default">
                                    <div class="elementor-widget-container">
                                        <div class="services-details__content-text3">
                                            <div class="content-box">
                                                <p>Get the most out of your technology investment with transformative integrated business IT solutions that enable you to achieve essential business goals and seize valuable opportunities for growth.ensure the
                                                    campaign is successful.</p>

                                                <ul class="ml-0">
                                                    <li>
                                                        <p>
                                                            <span aria-hidden="true" class="  icon-tick"></span> I must explain to you how all this mistaken idea </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span aria-hidden="true" class="  icon-tick"></span> At vero eos et accusamus et iusto odio </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span aria-hidden="true" class="  icon-tick"></span> Lorem ipsum dolor sit amet, consectetur elit </p>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="img-box">
                                                <img decoding="async" src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/services-details-img2.jpg" alt="alt" title="services-details-img2" /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-6d37bbe7 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="6d37bbe7" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6be4f1d5" data-id="6be4f1d5" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-4144c857 elementor-widget elementor-widget-ontech-google-maps" data-id="4144c857" data-element_type="widget" data-widget_type="ontech-google-maps.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-custom-embed">
                                            <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ItCunsultancy
