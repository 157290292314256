// Write your code here
import './TabItem.css'
import React, { useEffect } from "react";

const TabItem = props => {
  const {tabname, onchangestatus, isActive} = props
  const {tabId, displayText} = tabname
  const appclick = () => {
    onchangestatus(tabId)
  }
  const activeTabBtnClassName = isActive ? 'active-tab-btn' : 'button'

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // loop over all the entries
      entries.forEach((entry) => {
        console.log(entry);
        // use a conditional to check if the entry is intersection
        if (entry.isIntersecting) {
          // add show class
          entry.target.classList.add("show");
        } else {
          // remove when not intersecting
          entry.target.classList.remove("show");
        }
      });
    });
    
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));    
  })


  return (
    <li className="tabcontainer hidden">
      <button
        type="button"
        className={`tab-btn ${activeTabBtnClassName}`}
        onClick={appclick}
      >
        {displayText}
      </button>
    </li>
  )
}
export default TabItem
